import instance from "../utils/axios";

export const getUserBalance = async (data) => {
  // return await instance.post('/ledger/getUserBalance', data)
};


export const auraEventTypesWisePLNew = async (data) => {
  // return await instance.post('/reports/auraEventTypesWisePLNew', data)
};

export const getEvents = async (data) => {
  let url = "get-dashboard-data-with-user-id";
  if (!data.user_id) {
    url = "get-dashboard-data-without-user-id";
  }
  return await instance.post(`/eventsDashboard/${url}`, data)
};


export const getBlockEvents = async (data) => {
  return await instance.post('/blockMarket/blockEvents', data)
};


export const getBlockMarkets = async (data) => {
  return await instance.post('/blockMarket/blockMarketTypes', data)
};

