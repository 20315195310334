import '../../../App.css'
import { useState, useEffect, useMemo } from 'react';
import { Table } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios';
import Appconfig from '../../../config/config'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getAllEvents } from '../../../redux/slice/event/eventSlice';
import { useDispatch, useSelector } from 'react-redux';


function ManualOdds() {
    // const params = useParams();
    const dispatch = useDispatch();
    const eventType = 4

    const [allEvents, setAllEvents] = useState([]);

    const [events, setEvents] = useState([]);

    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    const userInfos = useSelector((state) => state.events); // Use selector to access state

    useEffect(() => {
        dispatch(getAllEvents({
            user_id: userInfo ? userInfo._id : "",
        }));
    }, [])

    useEffect(() => {
        if (userInfos.events.length) {
            const newEvents = userInfos?.events[0]?.competitions[0]?.events || [];
            if (newEvents.length > 0 && (allEvents.length == 0 || JSON.stringify(newEvents) !== JSON.stringify(allEvents))) {
                setAllEvents(newEvents);
            }
        }
    }, [userInfos.events.length])

    const filteredEvents = useMemo(() => {
        if (allEvents.length > 0) {
            return allEvents.filter(event => event.event_type == eventType);
        }
        return [];
    }, [allEvents, eventType]);

    useEffect(() => {
        setEvents(filteredEvents);
    }, [filteredEvents]);


    const columns = [
        {
            title: 'Name',
            sorter: true,
            render: (_, record) => (
                <span className='font-extrabold text-[#315195] text-[0.813rem] ml-[5px]'>{<Link to={"/manual-odds-event-data/" + record.event_id + "/" + record.event_name} >{record.event_name}</Link>}</span>
            ),
        },
        {
            title: 'Date',
            sorter: true,
            render: (_, record) => (
                <span className='font-extrabold text-[#212529] text-[0.813rem] ml-[5px]'>{new Date(record.open_date).toLocaleString()}</span>
            ),
        }

    ];

    return (
        <>
            <NotificationContainer />
            <div className='grid grid-cols-12 relative mt-[20px] lg:mt-[15px]  px-[15px] lg:px-[30px]' >
                <div className='col-span-12 lg:mx-auto  lg:w-full'>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12 px-[6px] py-[5px] font-bold text-[0.875rem] rounded-[1px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex flex-shrink-0 items-center justify-between'>
                            <h5 className='text-[0.938rem] font-bold mb-0 leading-normal'>Event Listing</h5>
                        </div>
                        <div className='col-span-12 '>

                            <div className='bg-[#fff] border-[1px] border-[#0000002d] '>

                                <div className='p-[0.75rem] flex-auto '>
                                    <Table

                                        columns={columns}
                                        className='downline-list'
                                        bordered
                                        dataSource={events}
                                        pagination={false}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default ManualOdds;
