// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBlockEvents } from '../../../services/users';

// Async Thunks for API calls
export const getAllBlockEvents = createAsyncThunk('/blockMarket/blockEvents', async (params, { rejectWithValue, dispatch }) => {
    try {

        const response = await getBlockEvents(params);

        return response.data;
    } catch (error) {
        // const errorMessage = error.response.data.message || 'something went wrong';
        return rejectWithValue(error.response.data);
    }
});


const blockEventSlice = createSlice({
    name: 'blockEvents',
    initialState: {
        blockEvents: [],
    },
    reducers: {
        setBlockEvents(state, action) {
            state.blockEvents = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllBlockEvents.pending, (state) => {
                state.blockEvents = [];
            })
            .addCase(getAllBlockEvents.fulfilled, (state, action) => {
                state.blockEvents = action.payload.resultData;
            })
            .addCase(getAllBlockEvents.rejected, (state, action) => {
                state.blockEvents = []
            })
    },
});

export const { setBlockEvents } = blockEventSlice.actions;


export default blockEventSlice.reducer;
