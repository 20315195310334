// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBlockMarkets } from '../../../services/users';

// Async Thunks for API calls
export const getAllBlockMarkets = createAsyncThunk('/blockMarket/blockMarketTypes', async (params, { rejectWithValue, dispatch }) => {
    try {

        const response = await getBlockMarkets(params);

        return response.data;
    } catch (error) {
        // const errorMessage = error.response.data.message || 'something went wrong';
        return rejectWithValue(error.response.data);
    }
});


const blockMarketSlice = createSlice({
    name: 'blockMarkets',
    initialState: {
        blockMarkets: [],
    },
    reducers: {
        setBlockMarkets(state, action) {
            state.blockMarkets = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllBlockMarkets.pending, (state) => {
                state.blockMarkets = [];
            })
            .addCase(getAllBlockMarkets.fulfilled, (state, action) => {
                state.blockMarkets = action.payload.resultData;
            })
            .addCase(getAllBlockMarkets.rejected, (state, action) => {
                state.blockMarkets = []
            })
    },
});

export const { setBlockMarkets } = blockMarketSlice.actions;


export default blockMarketSlice.reducer;
